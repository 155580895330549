@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/92e148d6fa54d7e7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/904d4c0518127b3f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: italic;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/0af3f9d765055047-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/abbd1c58df583079-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: italic;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/800cca6334f695a9-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/e4f0823674cdd050-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: italic;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/bbae810d30a5bdfd-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/cf66b688d8e0be98-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: italic;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/2ad17942138b3566-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/2ad17942138b3566-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: italic;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/0d7d9bdc1832803d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/fb51f5e4dc0f2ae8-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: italic;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/a76a28d994f7cc2e-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/a5f30d7635e57a10-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: italic;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/eb279b202642d3c3-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}

@font-face {
font-family: '__hellix_6a34b2';
src: url(/_next/static/media/d0c8dc67894e0083-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: italic;
}@font-face {font-family: '__hellix_Fallback_6a34b2';src: local("Arial");ascent-override: 79.18%;descent-override: 17.86%;line-gap-override: 19.43%;size-adjust: 103.05%
}.__className_6a34b2 {font-family: '__hellix_6a34b2', '__hellix_Fallback_6a34b2'
}

